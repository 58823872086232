<template>
  <div class="meeting-notes">
    <p class="meeting-notes-header">Meeting objective or notes</p>
    <div class="meeting-notes-list">
      <TextArea
        v-model="currentNote"
        :placeholder="editingNote ? 'Edit your note' : 'Add a note'"
        :button-text="saveButtonText"
        :has-cancel-button="editingNote !== ''"
        :loading="editLoading"
        class="meeting-notes-input"
        @submit="handleNoteSave"
        @cancel="cancelNoteEdit"
      />
      <MeetingNote
        v-for="(note, idx) in notes"
        :key="`selected-notes-${idx}`"
        :note="note"
        :user="note.member || { username: 'Unknown user' }"
        :loading="
          editingNote === note.uuid || removeLoading.includes(note.uuid)
        "
        :selected="true"
        @remove="() => deleteMeetingNote(note)"
        @edit="() => startNoteEdit(note)"
      />
      <div v-if="filteredAccountNotes.length">
        <p class="meeting-notes-title">Account notes</p>
        <p class="meeting-notes-header">
          Select notes for the selected company to use in your meeting
        </p>
      </div>
      <MeetingNote
        v-for="(note, idx) in filteredAccountNotes"
        :key="`account-notes-${idx}`"
        :note="note"
        :user="note.member || { username: 'Unknown user' }"
        :loading="
          editingNote === note.uuid || removeLoading.includes(note.uuid)
        "
        @remove="() => deleteAccountNote(note)"
        @edit="() => startNoteEdit(note)"
      >
        <template #actions>
          <Button
            text="Select"
            size="xs"
            icon="plus-medium"
            @click="() => collectAccountNote(note)"
          />
        </template>
      </MeetingNote>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import TextArea from '@c/library/TextArea.vue'
import MeetingNote from './notes/MeetingNote.vue'
import {
  createAccountNote,
  patchAccountNote,
  removeAccountNote
} from '@/services/accountService'

export default {
  name: 'MeetingNotes',
  components: {
    Button,
    TextArea,
    MeetingNote
  },
  props: {
    notes: {
      type: Array,
      default: () => []
    },
    account: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    currentNote: '',
    editingNote: '',
    editLoading: false,
    removeLoading: []
  }),
  computed: {
    saveButtonText() {
      return `${this.editingNote ? 'Save' : 'Add'} note`
    },
    accountNotes() {
      return this.account?.notes || []
    },
    filteredAccountNotes() {
      return this.accountNotes.filter(
        (note) => !this.notes.some((n) => n.uuid === note.uuid)
      )
    }
  },
  methods: {
    startNoteEdit(note) {
      this.editingNote = note.uuid
      this.currentNote = note.content
    },

    cancelNoteEdit() {
      this.editingNote = ''
      this.currentNote = ''
    },

    handleNoteSave() {
      if (this.editingNote) {
        this.handleEditNote()
      } else {
        this.addAccountNote(this.currentNote)
      }
    },

    async handleEditNote() {
      const uuid = this.editingNote
      const note = this.currentNote
      const idx = this.accountNotes.findIndex((n) => n.uuid === uuid)
      try {
        this.editLoading = true
        const editedNote = { ...this.accountNotes[idx] }
        editedNote.content = note
        editedNote.date_updated = new Date().toISOString()
        const res = await patchAccountNote({
          workspace_id: this.$route.params.workspace_id,
          account_id: this.account.uuid,
          note_id: editedNote.uuid,
          note: editedNote
        })
        this.$emit('edit', res)
        this.editingNote = ''
        this.currentNote = ''
      } catch (e) {
        this.$console.debug('Error while saving note', e)
        this.$toast.error(e, 'saving note')
      } finally {
        this.editLoading = false
      }
    },

    async addAccountNote(n) {
      if (!n) return
      try {
        this.editLoading = true

        let note = n
        let replaced = []
        if (note !== note.replace(/>>+/g, '>')) replaced.push('">"')
        note = note.replace(/>>+/g, '>') // Remove multiple '>', gets blocked by cloud armor otherwise
        if (note !== note.replace(/<<+/g, '<')) replaced.push('"<"')
        note = note.replace(/<<+/g, '<') // Remove multiple '<', gets blocked by cloud armor otherwise
        note = note.replace(/<>/g, '< >') // Remove empty tags, gets blocked by cloud armor otherwise

        const newNote = {
          content: note,
          date_created: new Date().toISOString()
        }
        const res = await createAccountNote({
          workspace_id: this.$route.params.workspace_id,
          account_id: this.account.uuid,
          note: newNote
        })
        await this.collectAccountNote(res)
        this.currentNote = ''

        if (replaced.length) {
          this.$toasts.info(
            this,
            'Note cleaned',
            `Repeated ${replaced.join(' and ')} characters were removed from your note to prevent issues. Please review it to make sure it is correct.`
          )
        }
      } catch (e) {
        this.$console.debug('Error while adding note', e)
        this.$toast.error(e, 'adding note')
      } finally {
        this.editLoading = false
      }
    },

    async collectAccountNote(note) {
      if (!note) return
      this.$emit('input', note)
    },

    async deleteAccountNote(note) {
      try {
        this.removeLoading = [...this.removeLoading, note.uuid]
        await removeAccountNote({
          workspace_id: this.$route.params.workspace_id,
          account_id: this.account.uuid,
          note_id: note.uuid
        })
        this.$emit('remove', note, true)
      } catch (e) {
        this.$console.debug('Error while removing note', e)
        this.$toast.error(e, 'removing note')
      } finally {
        this.removeLoading = this.removeLoading.filter((n) => n !== note.uuid)
      }
    },

    async deleteMeetingNote(note) {
      this.$emit('remove', note)
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-notes {
  &-header {
    font-size: 0.85rem;
    color: #60666b;
    padding-bottom: 0.25rem;
  }

  &-title {
    font-weight: 600;
  }

  &-input {
    margin-bottom: 1rem;
  }

  &-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;
  }
}
</style>
