<template>
  <div class="meeting-audience">
    <p class="meeting-audience-header">Which client will you be meeting?</p>
    <OrganisationSearch
      :organisation="organisation"
      :position-fixed="true"
      @input="handleOrganisationInput"
      @remove="() => $emit('remove')"
    />
  </div>
</template>

<script>
import OrganisationSearch from '@/views-v2/organisations/OrganisationSearch.vue'

export default {
  name: 'MeetingAccount',
  components: {
    OrganisationSearch
  },
  props: {
    organisation: {
      type: Object,
      default: undefined
    }
  },
  methods: {
    handleOrganisationInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-audience {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75rem;

  &-header {
    font-size: 0.85rem;
    color: #60666b;
    padding-bottom: 0.25rem;
  }
}
</style>
