var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "meeting-note",
      class: { loading: _vm.loading, selected: _vm.selected }
    },
    [
      _vm.loading
        ? _c("b-loading", { attrs: { "is-full-page": false, active: "" } })
        : _vm._e(),
      _c("div", { staticClass: "meeting-note-header" }, [
        _c("p", { staticClass: "meeting-note-title" }, [
          _vm._v(_vm._s(_vm.note.title))
        ]),
        _c(
          "div",
          { staticClass: "meeting-note-header-btns" },
          [
            _vm.authorIsCurrentUser
              ? _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: "Edit",
                      position: "is-top",
                      type: "is-dark"
                    }
                  },
                  [
                    _c("Button", {
                      attrs: { icon: "edit", type: "white", size: "xs" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("edit")
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.selected || _vm.authorIsCurrentUser
              ? _c(
                  "b-tooltip",
                  {
                    attrs: {
                      label: "Delete",
                      position: "is-top",
                      type: "is-dark"
                    }
                  },
                  [
                    _c("Button", {
                      attrs: { icon: "bin", type: "white", size: "xs" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("remove")
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._t("actions")
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "meeting-note-header" },
        [
          _c("Avatar", { attrs: { user: _vm.user, size: "xxs" } }),
          _c("p", { staticClass: "meeting-note-header-username" }, [
            _vm._v(_vm._s(_vm.$umodel.full_name(_vm.user)))
          ]),
          _vm.date
            ? _c("p", { staticClass: "meeting-note-header-date" }, [
                _vm._v(_vm._s(_vm.dateFormatted))
              ])
            : _vm._e()
        ],
        1
      ),
      _c("p", { staticClass: "meeting-note-content" }, [
        _vm._v(_vm._s(_vm.visibleNote))
      ]),
      _vm.hasShowMore
        ? _c(
            "p",
            {
              staticClass: "meeting-note-showmore",
              on: {
                click: function($event) {
                  _vm.showMore = !_vm.showMore
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.showMore ? "Show less" : "Show more") + " "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }