var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outputs-templates" }, [
    _c(
      "div",
      { staticClass: "outputs-templates-header" },
      [
        _c("p", { staticClass: "outputs-templates-header-title" }, [
          _vm._v("Templates")
        ]),
        _vm._t("header")
      ],
      2
    ),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "outputs-templates-items" },
          _vm._l(_vm.templates, function(template) {
            return _c(
              "TemplateCard",
              _vm._g(
                {
                  key: template.uuid + "-" + _vm.templatesKey,
                  staticClass: "outputs-templates-items-item",
                  attrs: {
                    template: template,
                    selectable: true,
                    selected: _vm.selected.includes(template.uuid),
                    disabled:
                      !!_vm.maxSelected &&
                      _vm.selected.length >= _vm.maxSelected &&
                      !_vm.selected.includes(template.uuid)
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "action",
                        fn: function() {
                          return [
                            _vm.generated.includes(template.uuid)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "outputs-templates-items-item-generated"
                                  },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "outputs-templates-items-item-generated-icon",
                                      attrs: {
                                        src: require("@/assets/icons/check-circle.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _vm._v(" Generated "),
                                    _c("Checkbox", {
                                      staticClass:
                                        "outputs-templates-items-item-generated-check",
                                      attrs: {
                                        value: _vm.selected.includes(
                                          template.uuid
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                _vm.$listeners
              )
            )
          }),
          1
        )
      : _c(
          "div",
          { staticClass: "outputs-templates-loading" },
          [_c("b-loading", { attrs: { "is-full-page": false, active: "" } })],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }