<template>
  <div
    class="content-card"
    :class="{
      selected: isSelected,
      bordered,
      inspectable,
      'no-image': !isPerson && !(image && !imageError),
      'no-keywords': !(keywords || item.confidentiality)
    }"
    @mouseenter="cardHovered = true"
    @mouseleave="cardHovered = false"
    @click.stop="() => $emit('click', item)"
  >
    <div class="content-card-wrapper" :class="{ 'has-avatar': isPerson }">
      <img
        v-if="image && !imageError"
        :src="image"
        alt=""
        class="content-card-img"
        :class="{ rounded: isPerson }"
        @error="imageError = true"
      />
      <Avatar
        v-else-if="isPerson"
        fallback-icon="user"
        :resizable="true"
        class="content-card-avatar"
      />
      <div
        class="content-card-header"
        :class="{ noscore: !(score || score === 0) }"
      >
        <div class="content-card-header-title">
          {{ name }}
          <img
            v-if="isPerson && item.url"
            src="@/assets/logo/linkedin.svg"
            alt=""
            class="content-card-header-title-link"
            @click="gotoUrl"
          />
        </div>
        <Score v-if="score || score === 0" :score="score" size="xs" />
        <Button
          :text="selectButtonText"
          :icon="selectButtonIcon"
          :loading="loading"
          :disabled="disabled"
          size="xs"
          class="content-card-header-btn"
          :class="{ visible: cardHovered || loading }"
          @click.native.stop="handleSelectButtonClick"
          @mouseenter.native="selectButtonHovered = true"
          @mouseleave.native="selectButtonHovered = false"
        />
      </div>
      <div v-if="keywords || item.confidentiality" class="content-card-content">
        <div class="content-card-props">
          <div class="content-card-props-item">
            {{ keywords }}
          </div>
          <div v-if="item.location" class="content-card-props-item">
            <img
              src="@/assets/icons/location.svg"
              alt=""
              class="content-card-props-item-icon"
            />
            {{ item.location }}
          </div>
        </div>
        <Tag
          v-if="item.confidentiality"
          :text="item.confidentiality.capitalize()"
          :type="item.confidentiality === 'internal' ? 'black' : 'light-solid'"
          :icon="`eye-${
            item.confidentiality === 'internal' ? 'close' : 'open'
          }`"
          :icon-left="true"
          size="xxs"
          class="reference-conf"
        />
      </div>
    </div>
    <MeetingORISubofferings
      v-if="enableSubofferings && item.subofferings"
      :key="item.subofferings.length"
      :offering="item"
      :selected="selected"
      :inspectable="inspectable"
      @click.native.stop
      @mouseenter.native="cardHovered = false"
      @mouseleave.native="cardHovered = true"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'
import { mapGetters } from 'vuex'
import MeetingORISubofferings from '../ori/MeetingORISubofferings.vue'
import Score from '@c/library/Score.vue'
import Tag from '@c/library/Tag.vue'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'MeetingContentCard',
  components: {
    Button,
    MeetingORISubofferings,
    Score,
    Tag,
    Avatar
  },
  props: {
    item: {
      type: Object,
      default: undefined
    },
    selected: {
      type: Array,
      default: () => []
    },
    bordered: {
      type: Boolean,
      default: false
    },
    isSuggestion: {
      type: Boolean,
      default: false
    },
    enableSubofferings: {
      type: Boolean,
      default: false
    },
    inspectable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectButtonHovered: false,
    cardHovered: false,
    loading: false,
    imageError: false
  }),
  computed: {
    ...mapGetters(['currentUser']),
    image() {
      return (
        this.item.image ||
        this.item.account?.organisation?.icon ||
        this.item.account?.organisation?.logo ||
        this.item.avatar ||
        ''
      )
    },
    isPerson() {
      return !!this.item.first_name
    },
    name() {
      return this.isPerson
        ? this.$umodel.full_name(this.item)
        : this.item.name || this.item.title
    },
    isSelected() {
      return this.selected.some((s) =>
        this.isPerson
          ? s.linkedin_url === this.item.url
          : s.uuid === this.item.uuid
      )
    },
    selectButtonIcon() {
      return this.isSelected
        ? this.isSuggestion
          ? this.selectButtonHovered
            ? 'bin'
            : 'check-medium'
          : 'bin'
        : 'plus-medium'
    },
    selectButtonText() {
      return this.isSelected
        ? this.isSuggestion
          ? this.selectButtonHovered
            ? 'Remove'
            : 'Added'
          : 'Remove'
        : 'Add'
    },
    keywords() {
      return this.item?.keywords?.join(', ') || this.item?.role || ''
    },
    score() {
      return this.item?.score
    }
  },
  watch: {
    isSelected(val) {
      if (val) {
        this.loading = false
      }
    }
  },
  methods: {
    handleSelectButtonClick() {
      if (this.loading || this.disabled) return
      this.loading = true
      this.$emit(this.isSelected ? 'remove' : 'add', this.item)
    },
    gotoUrl() {
      window.open(this.item.url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.content-card {
  background: white;
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &:hover {
    background: rgba(#000, 4%);
  }

  &.selected {
    background: rgba(#115efb, 4%);

    &:hover {
      background: rgba(#115efb, 8%);
    }
  }

  &.inspectable {
    cursor: zoom-in;
  }

  &.bordered {
    border: 1px solid rgba(#000, 8%);
    border-radius: 6px;
  }

  &.no-image {
    & > .content-card-wrapper {
      grid-template-columns: 1fr;
      grid-template-areas:
        'header'
        'content';
    }
    &.no-keywords {
      padding: 0.85rem;
      & > .content-card-wrapper {
        grid-template-rows: 1fr;
        grid-template-areas: 'header';
      }
    }
  }
  &.no-keywords {
    & > .content-card-wrapper {
      grid-template-areas:
        'image header'
        'image header';
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 4.25rem auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'image header'
      'image content';
    gap: 0.25rem 0.85rem;
    align-items: center;

    &.has-avatar {
      grid-template-columns: 3.25rem auto;
    }
  }

  &-img {
    grid-area: image;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;

    &.rounded {
      border-radius: 999rem;
    }
  }

  &-avatar {
    grid-area: image;
    width: 3.25rem;
    height: 3.25rem;
  }

  &-header {
    grid-area: header;
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: 1rem;

    &.noscore {
      grid-template-columns: 1fr auto;
    }

    &-title {
      font-weight: 600;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &-link {
        height: 1.2rem;
        width: 1.2rem;
        min-width: 1.2rem;
        cursor: pointer;
      }
    }

    &-btn {
      margin-left: auto;
      opacity: 0;
      transition: opacity 200ms ease;

      &.visible {
        opacity: 1;
      }
    }
  }

  &-content {
    grid-area: content;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }

  
  &-props {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-item {
      display: flex;
      flex-flow: row nowrap;
      gap: 0.25rem;
      align-items: center;
      font-size: 0.9rem;
      color: #60666b;

      &-icon {
        height: 1rem;
        opacity: 0.85;
      }
    }
  }
}
</style>
