<template>
  <div v-if="subofferings !== undefined" class="subofferings">
    <div v-if="subofferings.length" class="subofferings-list">
      <MeetingContentCard
        v-for="item in subofferings"
        :key="item.uuid"
        :item="item"
        :selected="selected"
        :bordered="false"
        :is-suggestion="!selected.some((s) => s.uuid === item.uuid)"
        :inspectable="inspectable"
        v-on="{
          ...$listeners,
          click: (item) => $emit('detail', item),
          doneTyping: () => handleDoneTyping(item),
          add: handleAdd
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingORISubofferings',
  components: {
    MeetingContentCard: () => import('../content/MeetingContentCard.vue')
  },
  props: {
    offering: {
      type: Object,
      default: () => {}
    },
    selected: {
      type: Array,
      default: () => []
    },
    inspectable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    expanded: false
  }),
  computed: {
    subofferings() {
      return this.offering?.subofferings || []
    }
  },
  methods: {
    handleAdd(item) {
      this.$emit('add', item)
    },
    scrollIntoView() {
      this.$nextTick(() => {
        this.$el.scrollIntoView({ behavior: 'smooth' })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.subofferings {
  &-list {
    display: flex;
    flex-flow: column nowrap;
    border-radius: 6px;
    border: 1px solid rgba(#000, 0.08);
    overflow: hidden;

    & > * {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(#000, 0.08);
      }
    }
  }

  &-btn {
    color: $primary;
    cursor: pointer;
    padding: 0.5rem 0;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
