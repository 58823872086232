var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-audience" },
    [
      _c("p", { staticClass: "meeting-audience-header" }, [
        _vm._v("Which client will you be meeting?")
      ]),
      _c("OrganisationSearch", {
        attrs: { organisation: _vm.organisation, "position-fixed": true },
        on: {
          input: _vm.handleOrganisationInput,
          remove: function() {
            return _vm.$emit("remove")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }