var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.subofferings !== undefined
    ? _c("div", { staticClass: "subofferings" }, [
        _vm.subofferings.length
          ? _c(
              "div",
              { staticClass: "subofferings-list" },
              _vm._l(_vm.subofferings, function(item) {
                return _c(
                  "MeetingContentCard",
                  _vm._g(
                    {
                      key: item.uuid,
                      attrs: {
                        item: item,
                        selected: _vm.selected,
                        bordered: false,
                        "is-suggestion": !_vm.selected.some(function(s) {
                          return s.uuid === item.uuid
                        }),
                        inspectable: _vm.inspectable
                      }
                    },
                    Object.assign({}, _vm.$listeners, {
                      click: function(item) {
                        return _vm.$emit("detail", item)
                      },
                      doneTyping: function() {
                        return _vm.handleDoneTyping(item)
                      },
                      add: _vm.handleAdd
                    })
                  )
                )
              }),
              1
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }