<template>
  <div>
    <b-modal
      :active="visible"
      :can-cancel="['escape', 'outside']"
      @close="() => $emit('close')"
    >
      <div
        class="edit-meeting"
        :class="{ restricted: selectedTab !== 'outputs' }"
      >
        <div class="edit-meeting-header">
          {{ modalHeader }}
          <Button
            icon="close"
            type="grey"
            size="xs"
            class="edit-meeting-header-close"
            @click="() => $emit('close')"
          />
        </div>
        <div v-if="tabs.length > 1" class="edit-meeting-tabs">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            class="edit-meeting-tabs-tab"
            :class="{
              active: selectedTab === tab.id,
              disabled: tab.disabled
            }"
            @click="() => selectTab(tab)"
          >
            <div class="edit-meeting-tabs-tab-icon-wrapper">
              <img
                :src="require(`@/assets/icons/${tab.icon}.svg`)"
                alt=""
                class="edit-meeting-tabs-tab-icon"
              />
            </div>
            <div class="edit-meeting-tabs-tab-title-wrapper">
              <p class="edit-meeting-tabs-tab-title">{{ tab.title }}</p>
              <Tag
                v-if="tab.items.length"
                :text="`${tab.items.length}`"
                size="xxs"
                type="light-solid"
                class="edit-meeting-tabs-tab-tag"
              />
            </div>
          </div>
        </div>
        <div
          class="edit-meeting-content"
          :class="{ padded: selectedTab !== 'outputs' }"
        >
          <div v-if="loading" class="edit-meeting-loading"></div>
          <MeetingAccount
            v-if="loadedTabs.includes('account')"
            :organisation="account && account.organisation"
            class="edit-meeting-content-tab"
            :class="{ visible: selectedTab === 'account' }"
            @input="addAccount"
            @remove="removeAccount"
          />
          <MeetingAudience
            v-if="loadedTabs.includes('audience')"
            :selection="people"
            :account="account"
            class="edit-meeting-content-tab"
            :class="{ visible: selectedTab === 'audience' }"
            @input="addAudience"
            @remove="removeAudience"
          />
          <MeetingNotes
            v-if="loadedTabs.includes('notes')"
            :notes="notes"
            :account="account"
            class="edit-meeting-content-tab"
            :class="{ visible: selectedTab === 'notes' }"
            @input="addNote"
            @edit="editNote"
            @remove="removeNote"
          />
          <MeetingOutputsSelect
            v-if="loadedTabs.includes('outputs')"
            :meeting="meeting"
            class="edit-meeting-content-tab"
            :class="{ visible: selectedTab === 'outputs' }"
            @select="handleTemplateSelect"
          />
        </div>
        <div class="edit-meeting-footer">
          <Button
            :text="backButton"
            icon="chevron-left-medium"
            :icon-left="true"
            :disabled="loading"
            type="white"
            @click="back"
          />
          <Button
            :text="nextButton"
            icon="chevron-right-medium"
            :disabled="nextDisabled"
            :loading="loading"
            @click="handleNext"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import Button from '@c/library/Button.vue'
import MeetingAccount from './MeetingAccount.vue'
import MeetingAudience from './MeetingAudience.vue'
import MeetingOutputsSelect from './outputs/MeetingOutputsSelect.vue'
import MeetingNotes from './MeetingNotes.vue'
import { createMeeting, editMeeting } from '@/services/meetingService'

export default {
  name: 'EditMeeting',
  components: {
    Tag,
    Button,
    MeetingAccount,
    MeetingAudience,
    MeetingNotes,
    MeetingOutputsSelect
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    meeting: {
      type: Object,
      default: undefined
    },
    forceAccount: {
      type: Object,
      default: () => undefined
    },
    forcePeople: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'create',
      validator: (v) => ['create', 'edit', 'assets'].includes(v)
    }
  },
  data: () => ({
    selectedTab: 'account',
    loadedTabs: ['account'],
    account: undefined,
    people: [],
    notes: [],
    templates: [],
    templateSettings: {},
    loading: false
  }),
  computed: {
    modalHeader() {
      return {
        create: 'Create meeting',
        edit: 'Edit meeting',
        assets: 'Select assets'
      }[this.mode]
    },
    tabs() {
      let tabs = [
        {
          id: 'account',
          title: 'Company',
          icon: 'company',
          items: []
        },
        {
          id: 'audience',
          title: 'Audience',
          icon: 'people',
          disabled: !this.account,
          items: this.people
        },
        {
          id: 'notes',
          title: 'Notes',
          icon: 'notepad',
          disabled: !this.account,
          items: this.notes
        },
        {
          id: 'outputs',
          title: 'Assets',
          icon: 'document',
          disabled: !this.account,
          items: this.templates
        }
      ]
      const allowed = {
        create: ['account', 'audience', 'notes', 'outputs'],
        edit: ['audience', 'notes', 'outputs'],
        assets: ['outputs']
      }[this.mode]
      return tabs.filter((t) => allowed.includes(t.id))
    },
    nextButton() {
      return {
        account: 'Select your audience',
        audience: 'Add notes',
        notes: 'Select assets',
        outputs:
          this.mode === 'edit' && (this.peopleChanged() || this.notesChanged())
            ? 'Go to assets'
            : 'Generate assets'
      }[this.selectedTab]
    },
    nextDisabled() {
      return this.selectedTab === 'outputs'
        ? this.mode === 'edit'
          ? !this.peopleChanged() &&
            !this.notesChanged() &&
            !this.templates.length
          : !this.templates.length
        : !this.account
    },
    backButton() {
      if (this.selectedTab === this.tabs[0].id) return 'Cancel'
      return {
        account: 'Cancel',
        audience: 'Edit company',
        notes: 'Edit audience',
        outputs: 'Edit notes'
      }[this.selectedTab]
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          if (this.forceAccount) this.account = this.forceAccount
          if (this.forcePeople?.length) this.people = this.forcePeople
          if (this.mode === 'edit') {
            this.account = { ...(this.meeting?.account || {}) }
            this.people = [...(this.meeting?.people || [])]
            this.notes = [...(this.meeting?.notes || [])]
            this.selectedTab = 'outputs'
            this.loadedTabs = ['outputs']
          }
          if (this.mode === 'assets') {
            this.selectedTab = 'outputs'
            this.loadedTabs = ['outputs']
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    back() {
      this.selectedTab === this.tabs[0].id
        ? this.$emit('close')
        : this.selectTab(
            this.tabs[this.tabs.findIndex((t) => t.id === this.selectedTab) - 1]
          )
    },
    selectTab(tab) {
      this.selectedTab = tab.id
      if (!this.loadedTabs.includes(tab.id)) {
        this.loadedTabs.push(tab.id)
      }
    },
    addAccount(account) {
      this.account = account
    },
    removeAccount() {
      this.account = undefined
      this.people = []
      this.notes = []
      this.templates = []
      this.templateSettings = {}
      this.loadedTabs = ['account']
    },
    addAudience(person) {
      const id = (p) => p.linkedin_url || p.role
      if (!this.people.find((p) => id(p) === id(person)))
        this.people.push(person)
    },
    removeAudience(person) {
      const idx = this.people.findIndex(
        (p) =>
          (p.linkedin_url || p.role) === (person.linkedin_url || person.role)
      )
      this.people.splice(idx, 1)
    },
    addNote(note) {
      this.notes.push(note)
      if (!(this.account.notes || []).some((n) => n.uuid === note.uuid)) {
        this.account.notes.push(note)
      }
    },
    editNote(note) {
      this.notes = this.notes.map((n) => (n.uuid === note.uuid ? note : n))
      this.account.notes = (this.account.notes || []).map((n) =>
        n.uuid === note.uuid ? note : n
      )
    },
    removeNote(note, account = false) {
      const notesIdx = this.notes.findIndex((n) => n.uuid === note.uuid)
      if (notesIdx > -1) this.notes.splice(notesIdx, 1)
      if (!account) return
      const accountIdx = (this.account.notes || []).findIndex(
        (n) => n.uuid === note.uuid
      )
      if (accountIdx > -1) this.account.notes.splice(accountIdx, 1)
    },
    handleTemplateSelect(templates, settings) {
      this.templates = templates
      this.templateSettings = settings
    },
    handleNext() {
      const tabIdx = this.tabs.findIndex((tab) => tab.id === this.selectedTab)
      if (tabIdx < this.tabs.length - 1) {
        this.selectTab(this.tabs[tabIdx + 1])
      } else {
        const finish = {
          create: this.createMeeting,
          edit: this.editMeeting,
          assets: this.emitAssets
        }[this.mode]
        finish()
      }
    },
    async createMeeting() {
      try {
        this.loading = true
        const meeting = await createMeeting({
          workspace_id: this.$route.params.workspace_id,
          account_id: this.account.uuid,
          people: this.people.map((p) => ({
            [p.linkedin_url ? 'linkedin_url' : 'role']: p.linkedin_url || p.role
          })),
          note_ids: this.notes.map((n) => n.uuid)
        })
        this.$emit('created', {
          meeting,
          templates: this.templates,
          settings: this.templateSettings
        })
      } catch (e) {
        this.$console.debug('Error creating meeting', e)
        this.$toast.error(e, 'creating meeting')
      } finally {
        this.loading = false
      }
    },
    peopleChanged() {
      let meetingPeople = this.meeting.people.map(
        (p) => p.linkedin_url || p.role
      )
      let newPeople = this.people.map((p) => p.linkedin_url || p.role)
      meetingPeople = meetingPeople.sort()
      newPeople = newPeople.sort()
      return (
        meetingPeople.length !== newPeople.length ||
        !meetingPeople.every((p, i) => p === newPeople[i])
      )
    },
    notesChanged() {
      let meetingNotes = this.meeting.notes.map((n) => n.uuid)
      let newNotes = this.notes.map((n) => n.uuid)
      meetingNotes = meetingNotes.sort()
      newNotes = newNotes.sort()
      return (
        meetingNotes.length !== newNotes.length ||
        !meetingNotes.every((n, i) => n === newNotes[i])
      )
    },
    async editMeeting() {
      try {
        let meeting = this.meeting
        let changed = false
        if (this.peopleChanged() || this.notesChanged()) {
          this.loading = true
          meeting = await editMeeting({
            workspace_id: this.$route.params.workspace_id,
            story_id: this.meeting.uuid,
            people: this.people.map((p) => ({
              [p.linkedin_url ? 'linkedin_url' : 'role']:
                p.linkedin_url || p.role
            })),
            note_ids: this.notes.map((n) => n.uuid)
          })
          changed = true
        }
        this.$emit('edit', {
          meeting,
          templates: this.templates,
          settings: this.templateSettings,
          changed
        })
      } catch (e) {
        this.$console.debug('Error editing meeting', e)
        this.$toast.error(e, 'editing meeting')
      } finally {
        this.loading = false
      }
    },
    emitAssets() {
      this.$emit('assets', {
        templates: this.templates,
        settings: this.templateSettings
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-meeting {
  border-radius: 8px;
  background: white;

  &.restricted {
    width: min(50rem, 90vw);
  }

  &-header {
    display: flex;
    align-items: center;
    padding: 1.25rem 1.5rem;
    border-bottom: 1px solid rgba(#000, 0.08);
    font-size: 1.25rem;
    font-weight: 700;

    &-close {
      margin-left: auto;
    }
  }

  &-tabs {
    display: flex;
    flex-flow: row nowrap;
    border-bottom: 1px solid rgba(#000, 0.08);

    &-tab {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 1rem;
      cursor: pointer;
      flex: 1;

      &:hover,
      &.active {
        background: rgba(#000, 0.04);
      }

      &:not(.active) {
        & .edit-meeting-tabs-tab-title {
          color: #8f9399;
        }
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;

        & .edit-meeting-tabs-tab-icon-wrapper {
          background: #8f9399;
        }
      }

      &-icon {
        width: 1.2rem;
        filter: brightness(0) invert(1);
        display: block;

        &-wrapper {
          border-radius: 4px;
          height: 1.5rem;
          width: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $primary;
        }
      }

      &-title {
        font-weight: 700;

        &-wrapper {
          position: relative;
        }
      }

      &-tag {
        position: absolute;
        top: 50%;
        left: calc(100% + 0.5rem);
        transform: translateY(-50%);
      }
    }
  }

  &-content {
    height: 70vh;
    max-height: 70vh;
    overflow-y: auto;
    position: relative;

    &.padded {
      padding: 1.5rem;
    }

    &-tab {
      &:not(.visible) {
        display: none;
      }
    }
  }

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 100;
  }

  &-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0.75rem;
  }
}

::v-deep .modal-content {
  width: unset !important;
  max-width: unset !important;
  margin: auto 0;
}

::v-deep .modal.is-active {
  padding: 0 !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
