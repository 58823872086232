var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "meeting-outputs" }, [
    _c(
      "div",
      { staticClass: "meeting-outputs-filters" },
      [
        _c("MeetingOutputsFilters", {
          key: _vm.selectedFiltersKey,
          attrs: {
            filters: _vm.filters,
            selected: _vm.selectedFilters,
            loading: _vm.loadingTemplates,
            "max-selected": _vm.maxSelected
          },
          on: { select: _vm.handleSelectFilter, selectAll: _vm.handleSelectAll }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "meeting-outputs-templates" },
      [
        _c("MeetingOutputsTemplates", {
          attrs: {
            templates: _vm.filteredTemplates,
            selected: _vm.selectedUuids,
            loading: _vm.loadingTemplates,
            "max-selected": _vm.maxSelected,
            generated: _vm.generatedTemplates
          },
          on: { select: _vm.handleSelectTemplate },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function() {
                return [
                  _c("TextInput", {
                    staticClass: "meeting-outputs-templates-search",
                    attrs: { "left-icon": "search", placeholder: "Search..." },
                    model: {
                      value: _vm.query,
                      callback: function($$v) {
                        _vm.query = $$v
                      },
                      expression: "query"
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "meeting-outputs-selection" },
      [
        _c("MeetingOutputsSelection", {
          key: _vm.selectionKey,
          attrs: {
            selected: _vm.selected,
            settings: _vm.selectedSettings,
            meeting: _vm.meeting,
            "hide-settings": _vm.hideSettings
          },
          on: {
            select: _vm.handleRemoveTemplate,
            settings: _vm.handleChangeSettings
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }