<template>
  <div class="meeting-note" :class="{ loading, selected }">
    <b-loading v-if="loading" :is-full-page="false" active></b-loading>
    <div class="meeting-note-header">
      <p class="meeting-note-title">{{ note.title }}</p>
      <div class="meeting-note-header-btns">
        <b-tooltip
          v-if="authorIsCurrentUser"
          label="Edit"
          position="is-top"
          type="is-dark"
        >
          <Button icon="edit" type="white" size="xs" @click="$emit('edit')" />
        </b-tooltip>
        <b-tooltip
          v-if="selected || authorIsCurrentUser"
          label="Delete"
          position="is-top"
          type="is-dark"
        >
          <Button icon="bin" type="white" size="xs" @click="$emit('remove')" />
        </b-tooltip>
        <slot name="actions"> </slot>
      </div>
    </div>
    <div class="meeting-note-header">
      <Avatar :user="user" size="xxs" />
      <p class="meeting-note-header-username">{{ $umodel.full_name(user) }}</p>
      <p v-if="date" class="meeting-note-header-date">{{ dateFormatted }}</p>
    </div>
    <p class="meeting-note-content">{{ visibleNote }}</p>
    <p
      v-if="hasShowMore"
      class="meeting-note-showmore"
      @click="showMore = !showMore"
    >
      {{ showMore ? 'Show less' : 'Show more' }}
    </p>
  </div>
</template>

<script>
import { relativeTime } from '@/util'
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'MeetingNote',
  components: { Avatar, Button },
  props: {
    note: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showMoreCutoff: 650,
    showMore: false
  }),
  computed: {
    ...mapGetters(['currentWorkspaceMember']),
    hasUser() {
      return !isEmpty(this.user)
    },
    authorIsCurrentUser() {
      return this.hasUser && this.user.uuid === this.currentWorkspaceMember.uuid
    },
    date() {
      return this.note?.date_updated || this.note?.date_created
        ? new Date(this.note?.date_updated || this.note?.date_created)
        : ''
    },
    dateFormatted() {
      return this.date ? relativeTime(this.date) : ''
    },
    hasShowMore() {
      return this.note?.content?.length > this.showMoreCutoff
    },
    visibleNote() {
      return this.showMore
        ? this.note.content
        : `${this.note?.content?.slice(0, this.showMoreCutoff)}${
            this.hasShowMore ? '...' : ''
          }`
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-note {
  position: relative;
  padding: 1.15rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  background: rgba(#000, 0.02);
  border-radius: 8px;
  border: 1px solid rgba(#000, 0.08);

  &:not(.loading) {
    &:hover {
      background: rgba(#000, 0.04);

      & .meeting-note-header-btns {
        opacity: 1;
      }
    }
  }

  &.selected {
    background: rgba(#115efb, 4%);

    &:hover {
      background: rgba(#115efb, 8%);
    }
  }

  &-title {
    font-weight: 700;
  }

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;

    &-username {
      color: #60666b;
      font-weight: 600;
    }

    &-dot {
      color: #60666b;
      margin: 0 0.5rem;
    }

    &-date {
      color: #60666b;
    }

    &-btns {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
  }

  &-content {
    white-space: pre-line;
    padding-top: 0.5rem;
  }

  &-showmore {
    color: $primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
