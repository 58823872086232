var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { active: _vm.visible, "can-cancel": ["escape", "outside"] },
          on: {
            close: function() {
              return _vm.$emit("close")
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "edit-meeting",
              class: { restricted: _vm.selectedTab !== "outputs" }
            },
            [
              _c(
                "div",
                { staticClass: "edit-meeting-header" },
                [
                  _vm._v(" " + _vm._s(_vm.modalHeader) + " "),
                  _c("Button", {
                    staticClass: "edit-meeting-header-close",
                    attrs: { icon: "close", type: "grey", size: "xs" },
                    on: {
                      click: function() {
                        return _vm.$emit("close")
                      }
                    }
                  })
                ],
                1
              ),
              _vm.tabs.length > 1
                ? _c(
                    "div",
                    { staticClass: "edit-meeting-tabs" },
                    _vm._l(_vm.tabs, function(tab) {
                      return _c(
                        "div",
                        {
                          key: tab.id,
                          staticClass: "edit-meeting-tabs-tab",
                          class: {
                            active: _vm.selectedTab === tab.id,
                            disabled: tab.disabled
                          },
                          on: {
                            click: function() {
                              return _vm.selectTab(tab)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "edit-meeting-tabs-tab-icon-wrapper"
                            },
                            [
                              _c("img", {
                                staticClass: "edit-meeting-tabs-tab-icon",
                                attrs: {
                                  src: require("@/assets/icons/" +
                                    tab.icon +
                                    ".svg"),
                                  alt: ""
                                }
                              })
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "edit-meeting-tabs-tab-title-wrapper"
                            },
                            [
                              _c(
                                "p",
                                { staticClass: "edit-meeting-tabs-tab-title" },
                                [_vm._v(_vm._s(tab.title))]
                              ),
                              tab.items.length
                                ? _c("Tag", {
                                    staticClass: "edit-meeting-tabs-tab-tag",
                                    attrs: {
                                      text: "" + tab.items.length,
                                      size: "xxs",
                                      type: "light-solid"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "edit-meeting-content",
                  class: { padded: _vm.selectedTab !== "outputs" }
                },
                [
                  _vm.loading
                    ? _c("div", { staticClass: "edit-meeting-loading" })
                    : _vm._e(),
                  _vm.loadedTabs.includes("account")
                    ? _c("MeetingAccount", {
                        staticClass: "edit-meeting-content-tab",
                        class: { visible: _vm.selectedTab === "account" },
                        attrs: {
                          organisation: _vm.account && _vm.account.organisation
                        },
                        on: { input: _vm.addAccount, remove: _vm.removeAccount }
                      })
                    : _vm._e(),
                  _vm.loadedTabs.includes("audience")
                    ? _c("MeetingAudience", {
                        staticClass: "edit-meeting-content-tab",
                        class: { visible: _vm.selectedTab === "audience" },
                        attrs: { selection: _vm.people, account: _vm.account },
                        on: {
                          input: _vm.addAudience,
                          remove: _vm.removeAudience
                        }
                      })
                    : _vm._e(),
                  _vm.loadedTabs.includes("notes")
                    ? _c("MeetingNotes", {
                        staticClass: "edit-meeting-content-tab",
                        class: { visible: _vm.selectedTab === "notes" },
                        attrs: { notes: _vm.notes, account: _vm.account },
                        on: {
                          input: _vm.addNote,
                          edit: _vm.editNote,
                          remove: _vm.removeNote
                        }
                      })
                    : _vm._e(),
                  _vm.loadedTabs.includes("outputs")
                    ? _c("MeetingOutputsSelect", {
                        staticClass: "edit-meeting-content-tab",
                        class: { visible: _vm.selectedTab === "outputs" },
                        attrs: { meeting: _vm.meeting },
                        on: { select: _vm.handleTemplateSelect }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-meeting-footer" },
                [
                  _c("Button", {
                    attrs: {
                      text: _vm.backButton,
                      icon: "chevron-left-medium",
                      "icon-left": true,
                      disabled: _vm.loading,
                      type: "white"
                    },
                    on: { click: _vm.back }
                  }),
                  _c("Button", {
                    attrs: {
                      text: _vm.nextButton,
                      icon: "chevron-right-medium",
                      disabled: _vm.nextDisabled,
                      loading: _vm.loading
                    },
                    on: { click: _vm.handleNext }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }