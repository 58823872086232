<template>
  <div class="suggestions">
    <div class="suggestions-wrapper">
      <p class="suggestions-header">Suggestions</p>
      <div v-if="filters && filters.length" class="suggestions-filters">
        <Button
          v-for="filter in filters"
          :key="filter"
          :text="filter.capitalize()"
          :type="
            enabledFilters.includes(filter) ||
            (filter === 'Show all' && !enabledFilters.length)
              ? 'light-solid'
              : 'white'
          "
          @click="handleFilterClick(filter)"
        />
        <Button
          v-if="showShowMoreFilters"
          text="More filters"
          type="grey"
          @click="() => (showMoreFilters = true)"
        />
      </div>
      <div class="suggestions-content">
        <div v-if="sortedSuggestions.length" class="suggestions-content-block">
          <MeetingContentCard
            v-for="item in sortedSuggestions"
            :key="item.uuid || item.url"
            :item="item"
            :selected="selected"
            :bordered="true"
            :is-suggestion="true"
            :enable-subofferings="true"
            :has-modal="type !== 'audience'"
            :inspectable="inspectable"
            :disabled="disabled"
            @click="() => $emit('detail', item)"
            v-on="$listeners"
          />
        </div>
        <MeetingContentError
          v-if="error"
          :type="type"
          @retry="$emit('retry')"
        />
        <MeetingContentLoading v-else-if="loading" :type="type" />
        <div
          v-else-if="!loading && !sortedSuggestions.length"
          class="suggestions-content-empty"
        >
          No suggestions available
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingContentCard from '../content/MeetingContentCard.vue'
import MeetingContentError from '../content/MeetingContentError.vue'
import MeetingContentLoading from '../content/MeetingContentLoading.vue'
import Button from '@c/library/Button.vue'

export default {
  name: 'MeetingORISuggestions',
  components: {
    MeetingContentLoading,
    MeetingContentCard,
    MeetingContentError,
    Button
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    selected: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inspectable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    enabledFilters: [],
    showMoreFilters: false,
    filtersPageSize: 5
  }),
  computed: {
    hasFilters() {
      return this.type === 'audience'
    },
    filterValues() {
      if (!this.hasFilters) return []
      return this.suggestions.map((s) => s.department)
    },
    visibleFilters() {
      return this.showMoreFilters
        ? this.filterValues
        : this.filterValues.slice(0, this.filtersPageSize)
    },
    filters() {
      return this.visibleFilters.length && this.type !== 'audience'
        ? ['Show all', ...this.visibleFilters]
        : this.visibleFilters
    },
    showShowMoreFilters() {
      return (
        this.filterValues.length > this.filtersPageSize && !this.showMoreFilters
      )
    },
    filteredSuggestions() {
      if (this.type !== 'audience') return this.suggestions
      const filtered = this.enabledFilters.length
        ? this.suggestions.find((s) => s.department === this.enabledFilters[0])
            .persons
        : this.suggestions.map((s) => s.persons).flat()

      return filtered.filter(
        (s) =>
          !this.selected.some((sel) => (sel.linkedin_url || sel.url) === s.url)
      )
    },
    sortedSuggestions() {
      const parents = this.filteredSuggestions.filter((s) => !s.parent)
      const children = this.filteredSuggestions.filter((s) => s.parent)
      return [
        ...parents.map((p) => {
          p.subofferings = children.filter((c) => c.parent.uuid === p.uuid)
          return p
        }),
        ...children.filter(
          (c) => !parents.some((p) => p.uuid === c.parent.uuid)
        )
      ]
    }
  },
  watch: {
    suggestions(newVal) {
      if (this.type === 'audience' && newVal.length)
        this.enabledFilters = [newVal[0].department]
    }
  },
  methods: {
    handleFilterClick(filter) {
      if (filter === 'Show all') {
        this.enabledFilters = []
      } else if (this.enabledFilters.includes(filter)) {
        this.enabledFilters = this.enabledFilters.filter((t) => t !== filter)
      } else {
        this.enabledFilters =
          this.type === 'audience' ? [filter] : [...this.enabledFilters, filter]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.suggestions {
  &-header {
    font-weight: 700;
    margin-top: 0.5rem;
  }

  &-content {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-block {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
    }

    &-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.75rem;
      color: #60666b;
      background: rgba(0, 0, 0, 0.02);
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.08);
    }
  }

  &-btn {
    color: $primary;
    cursor: pointer;
    padding-top: 0.5rem;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }

  &-visible {
    color: $primary;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    margin-top: 0.5rem;
    border-bottom: 1px solid transparent;

    &-icon {
      height: 1rem;
      filter: brightness(0) saturate(100%) invert(25%) sepia(95%)
        saturate(2915%) hue-rotate(217deg) brightness(97%) contrast(103%);
    }

    &:hover {
      border-bottom: 1px solid $primary;
    }
  }

  &-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.75rem;
  }

  &-filters {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
  }
}
</style>
