var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-card",
      class: {
        selected: _vm.isSelected,
        bordered: _vm.bordered,
        inspectable: _vm.inspectable,
        "no-image": !_vm.isPerson && !(_vm.image && !_vm.imageError),
        "no-keywords": !(_vm.keywords || _vm.item.confidentiality)
      },
      on: {
        mouseenter: function($event) {
          _vm.cardHovered = true
        },
        mouseleave: function($event) {
          _vm.cardHovered = false
        },
        click: function($event) {
          $event.stopPropagation()
          return function() {
            return _vm.$emit("click", _vm.item)
          }.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "content-card-wrapper",
          class: { "has-avatar": _vm.isPerson }
        },
        [
          _vm.image && !_vm.imageError
            ? _c("img", {
                staticClass: "content-card-img",
                class: { rounded: _vm.isPerson },
                attrs: { src: _vm.image, alt: "" },
                on: {
                  error: function($event) {
                    _vm.imageError = true
                  }
                }
              })
            : _vm.isPerson
            ? _c("Avatar", {
                staticClass: "content-card-avatar",
                attrs: { "fallback-icon": "user", resizable: true }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "content-card-header",
              class: { noscore: !(_vm.score || _vm.score === 0) }
            },
            [
              _c("div", { staticClass: "content-card-header-title" }, [
                _vm._v(" " + _vm._s(_vm.name) + " "),
                _vm.isPerson && _vm.item.url
                  ? _c("img", {
                      staticClass: "content-card-header-title-link",
                      attrs: {
                        src: require("@/assets/logo/linkedin.svg"),
                        alt: ""
                      },
                      on: { click: _vm.gotoUrl }
                    })
                  : _vm._e()
              ]),
              _vm.score || _vm.score === 0
                ? _c("Score", { attrs: { score: _vm.score, size: "xs" } })
                : _vm._e(),
              _c("Button", {
                staticClass: "content-card-header-btn",
                class: { visible: _vm.cardHovered || _vm.loading },
                attrs: {
                  text: _vm.selectButtonText,
                  icon: _vm.selectButtonIcon,
                  loading: _vm.loading,
                  disabled: _vm.disabled,
                  size: "xs"
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.handleSelectButtonClick.apply(null, arguments)
                  },
                  mouseenter: function($event) {
                    _vm.selectButtonHovered = true
                  },
                  mouseleave: function($event) {
                    _vm.selectButtonHovered = false
                  }
                }
              })
            ],
            1
          ),
          _vm.keywords || _vm.item.confidentiality
            ? _c(
                "div",
                { staticClass: "content-card-content" },
                [
                  _c("div", { staticClass: "content-card-props" }, [
                    _c("div", { staticClass: "content-card-props-item" }, [
                      _vm._v(" " + _vm._s(_vm.keywords) + " ")
                    ]),
                    _vm.item.location
                      ? _c("div", { staticClass: "content-card-props-item" }, [
                          _c("img", {
                            staticClass: "content-card-props-item-icon",
                            attrs: {
                              src: require("@/assets/icons/location.svg"),
                              alt: ""
                            }
                          }),
                          _vm._v(" " + _vm._s(_vm.item.location) + " ")
                        ])
                      : _vm._e()
                  ]),
                  _vm.item.confidentiality
                    ? _c("Tag", {
                        staticClass: "reference-conf",
                        attrs: {
                          text: _vm.item.confidentiality.capitalize(),
                          type:
                            _vm.item.confidentiality === "internal"
                              ? "black"
                              : "light-solid",
                          icon:
                            "eye-" +
                            (_vm.item.confidentiality === "internal"
                              ? "close"
                              : "open"),
                          "icon-left": true,
                          size: "xxs"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.enableSubofferings && _vm.item.subofferings
        ? _c(
            "MeetingORISubofferings",
            _vm._g(
              {
                key: _vm.item.subofferings.length,
                attrs: {
                  offering: _vm.item,
                  selected: _vm.selected,
                  inspectable: _vm.inspectable
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                  },
                  mouseenter: function($event) {
                    _vm.cardHovered = false
                  },
                  mouseleave: function($event) {
                    _vm.cardHovered = true
                  }
                }
              },
              _vm.$listeners
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }