var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "meeting-notes" }, [
    _c("p", { staticClass: "meeting-notes-header" }, [
      _vm._v("Meeting objective or notes")
    ]),
    _c(
      "div",
      { staticClass: "meeting-notes-list" },
      [
        _c("TextArea", {
          staticClass: "meeting-notes-input",
          attrs: {
            placeholder: _vm.editingNote ? "Edit your note" : "Add a note",
            "button-text": _vm.saveButtonText,
            "has-cancel-button": _vm.editingNote !== "",
            loading: _vm.editLoading
          },
          on: { submit: _vm.handleNoteSave, cancel: _vm.cancelNoteEdit },
          model: {
            value: _vm.currentNote,
            callback: function($$v) {
              _vm.currentNote = $$v
            },
            expression: "currentNote"
          }
        }),
        _vm._v(" "),
        _vm._l(_vm.notes, function(note, idx) {
          return _c("MeetingNote", {
            key: "selected-notes-" + idx,
            attrs: {
              note: note,
              user: note.member || { username: "Unknown user" },
              loading:
                _vm.editingNote === note.uuid ||
                _vm.removeLoading.includes(note.uuid),
              selected: true
            },
            on: {
              remove: function() {
                return _vm.deleteMeetingNote(note)
              },
              edit: function() {
                return _vm.startNoteEdit(note)
              }
            }
          })
        }),
        _vm.filteredAccountNotes.length
          ? _c("div", [
              _c("p", { staticClass: "meeting-notes-title" }, [
                _vm._v("Account notes")
              ]),
              _c("p", { staticClass: "meeting-notes-header" }, [
                _vm._v(
                  " Select notes for the selected company to use in your meeting "
                )
              ])
            ])
          : _vm._e(),
        _vm._l(_vm.filteredAccountNotes, function(note, idx) {
          return _c("MeetingNote", {
            key: "account-notes-" + idx,
            attrs: {
              note: note,
              user: note.member || { username: "Unknown user" },
              loading:
                _vm.editingNote === note.uuid ||
                _vm.removeLoading.includes(note.uuid)
            },
            on: {
              remove: function() {
                return _vm.deleteAccountNote(note)
              },
              edit: function() {
                return _vm.startNoteEdit(note)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "actions",
                  fn: function() {
                    return [
                      _c("Button", {
                        attrs: {
                          text: "Select",
                          size: "xs",
                          icon: "plus-medium"
                        },
                        on: {
                          click: function() {
                            return _vm.collectAccountNote(note)
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }