var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outputs-selection" },
    [
      _c("p", { staticClass: "outputs-selection-header" }, [
        _vm._v("Selection")
      ]),
      _vm._l(_vm.selected, function(template) {
        return _c(
          "div",
          { key: template.uuid, staticClass: "outputs-selection-item" },
          [
            _c("img", {
              staticClass: "outputs-selection-item-icon",
              attrs: {
                src: require("@/assets/file_type/" +
                  (template.type === "presentation" ? "powerpoint" : "word") +
                  ".svg"),
                alt: ""
              }
            }),
            _c("p", { staticClass: "outputs-selection-item-name" }, [
              _vm._v(_vm._s(template.name))
            ]),
            _c(
              "div",
              { staticClass: "outputs-selection-item-action" },
              [
                !_vm.hideSettings
                  ? _c("LanguageSelect", {
                      attrs: {
                        value: _vm.languageSetting(template),
                        "position-fixed": true,
                        short: true,
                        "content-style": { minWidth: "10rem" }
                      },
                      on: {
                        input: function(language) {
                          return _vm.handleLanguageChange(template, language)
                        }
                      },
                      nativeOn: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    })
                  : _vm._e(),
                _c("Button", {
                  attrs: { icon: "bin", type: "white", size: "xs" },
                  on: {
                    click: function() {
                      return _vm.handleItemClick(template)
                    }
                  }
                })
              ],
              1
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }