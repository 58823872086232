var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "suggestions" }, [
    _c("div", { staticClass: "suggestions-wrapper" }, [
      _c("p", { staticClass: "suggestions-header" }, [_vm._v("Suggestions")]),
      _vm.filters && _vm.filters.length
        ? _c(
            "div",
            { staticClass: "suggestions-filters" },
            [
              _vm._l(_vm.filters, function(filter) {
                return _c("Button", {
                  key: filter,
                  attrs: {
                    text: filter.capitalize(),
                    type:
                      _vm.enabledFilters.includes(filter) ||
                      (filter === "Show all" && !_vm.enabledFilters.length)
                        ? "light-solid"
                        : "white"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleFilterClick(filter)
                    }
                  }
                })
              }),
              _vm.showShowMoreFilters
                ? _c("Button", {
                    attrs: { text: "More filters", type: "grey" },
                    on: {
                      click: function() {
                        return (_vm.showMoreFilters = true)
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "suggestions-content" },
        [
          _vm.sortedSuggestions.length
            ? _c(
                "div",
                { staticClass: "suggestions-content-block" },
                _vm._l(_vm.sortedSuggestions, function(item) {
                  return _c(
                    "MeetingContentCard",
                    _vm._g(
                      {
                        key: item.uuid || item.url,
                        attrs: {
                          item: item,
                          selected: _vm.selected,
                          bordered: true,
                          "is-suggestion": true,
                          "enable-subofferings": true,
                          "has-modal": _vm.type !== "audience",
                          inspectable: _vm.inspectable,
                          disabled: _vm.disabled
                        },
                        on: {
                          click: function() {
                            return _vm.$emit("detail", item)
                          }
                        }
                      },
                      _vm.$listeners
                    )
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.error
            ? _c("MeetingContentError", {
                attrs: { type: _vm.type },
                on: {
                  retry: function($event) {
                    return _vm.$emit("retry")
                  }
                }
              })
            : _vm.loading
            ? _c("MeetingContentLoading", { attrs: { type: _vm.type } })
            : !_vm.loading && !_vm.sortedSuggestions.length
            ? _c("div", { staticClass: "suggestions-content-empty" }, [
                _vm._v(" No suggestions available ")
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }