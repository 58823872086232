var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "outputs-filters" },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "outputs-filters-loading" },
            [_c("b-loading", { attrs: { "is-full-page": false, active: "" } })],
            1
          )
        : _vm._e(),
      _vm._l(_vm.loading ? 0 : _vm.filters, function(filter) {
        return _c(
          "div",
          { key: filter.id, staticClass: "outputs-filters-filter" },
          [
            _c("p", { staticClass: "outputs-filters-filter-header" }, [
              _vm._v(_vm._s(filter.name))
            ]),
            _c(
              "div",
              { staticClass: "outputs-filters-filter-list" },
              _vm._l(filter.items, function(option) {
                return _c(
                  "div",
                  {
                    key: option.id,
                    staticClass: "outputs-filters-filter-list-item",
                    on: {
                      click: function() {
                        return _vm.$emit("select", filter, option)
                      }
                    }
                  },
                  [
                    _c("Checkbox", {
                      staticClass: "outputs-filters-filter-list-item-checkbox",
                      attrs: { value: _vm.isSelected(filter, option) }
                    }),
                    _c(
                      "p",
                      { staticClass: "outputs-filters-filter-list-item-name" },
                      [_vm._v(_vm._s(option.name))]
                    ),
                    !_vm.maxSelected
                      ? _c(
                          "p",
                          {
                            staticClass:
                              "outputs-filters-filter-list-item-selectall",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return function() {
                                  return _vm.$emit("selectAll", filter, option)
                                }.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v(" Select all ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              0
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }