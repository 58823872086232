<template>
  <div class="outputs-templates">
    <div class="outputs-templates-header">
      <p class="outputs-templates-header-title">Templates</p>
      <slot name="header"></slot>
    </div>
    <div v-if="!loading" class="outputs-templates-items">
      <TemplateCard
        v-for="template in templates"
        :key="`${template.uuid}-${templatesKey}`"
        :template="template"
        :selectable="true"
        :selected="selected.includes(template.uuid)"
        :disabled="
          !!maxSelected &&
            selected.length >= maxSelected &&
            !selected.includes(template.uuid)
        "
        class="outputs-templates-items-item"
        v-on="$listeners"
      >
        <template #action>
          <div
            v-if="generated.includes(template.uuid)"
            class="outputs-templates-items-item-generated"
          >
            <img
              src="@/assets/icons/check-circle.svg"
              alt=""
              class="outputs-templates-items-item-generated-icon"
            />
            Generated
            <Checkbox
              :value="selected.includes(template.uuid)"
              class="outputs-templates-items-item-generated-check"
            />
          </div>
        </template>
      </TemplateCard>
    </div>
    <div v-else class="outputs-templates-loading">
      <b-loading :is-full-page="false" active />
    </div>
  </div>
</template>

<script>
import TemplateCard from '@/views-v2/templates/TemplateCard.vue'
import Checkbox from '@c/library/Checkbox.vue'

export default {
  name: 'MeetingOutputsTemplates',
  components: { TemplateCard, Checkbox },
  props: {
    templates: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    generated: {
      type: Array,
      default: () => []
    },
    maxSelected: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    templatesKey: 0
  }),
  watch: {
    templates() {
      this.templatesKey++
    },
    selected() {
      this.templatesKey++
    }
  }
}
</script>

<style lang="scss" scoped>
.outputs-templates {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  max-height: 100%;
  overflow-y: auto;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2.5rem;
    gap: 1rem;
    border-bottom: solid 1px rgba(#000, 0.08);

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  &-items {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 1rem;
    padding: 1.5rem 2.5rem 2rem;

    &-item {
      &-generated {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        color: #24ce85;
        width: max-content;

        &-icon {
          height: 1.2rem;
          filter: brightness(0) saturate(100%) invert(77%) sepia(18%)
            saturate(3433%) hue-rotate(96deg) brightness(94%) contrast(72%);
        }

        &-check {
          pointer-events: none;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &-loading {
    position: relative;
    min-height: 5rem;
    padding: 2.5rem 0;
  }
}
</style>
