var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scorecard" }, [
    _vm.type === "circle"
      ? _c(
          "div",
          { staticClass: "scorecard-items" },
          _vm._l(_vm.maxScore, function(s) {
            var _obj
            return _c(
              "div",
              {
                key: s,
                class:
                  ((_obj = {}),
                  (_obj[_vm.scoreColor] = s <= _vm.score && !_vm.loading),
                  (_obj["scorecard-items-item"] = !_vm.loading),
                  _obj),
                style: _vm.sizeStyle
              },
              [
                _vm.loading
                  ? _c("b-skeleton", {
                      attrs: {
                        circle: "",
                        height: _vm.sizeStyle.height,
                        width: _vm.sizeStyle.width
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }